<template>
  <div class="d-flex align-center mb-2">
    <div class="overline font-weight-bold mr-2">
      {{ text }}
    </div>

    <v-divider></v-divider>
  </div>
</template>

<script>

export default {
  props: ['text']
}

</script>
